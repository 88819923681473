import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { CssBaseline, GeistProvider } from "@geist-ui/react";
import "firebase/database";
import reportWebVitals from "./reportWebVitals";
import { setupFirestore } from "./firebaseApp";

setupFirestore();

ReactDOM.render(
  <React.StrictMode>
    <GeistProvider>
      <CssBaseline />
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </GeistProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
