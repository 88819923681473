import {
  Text,
  Button,
  Display,
  Grid,
  Page,
  Avatar,
  Link,
} from "@geist-ui/react";
import { Code, Github, UserX } from "@geist-ui/react-icons";
import firebase from "firebase/app";
import "firebase/auth";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useOnPromise } from "../functions/useOnPromise";

const HomePage = () => {
  const history = useHistory();
  const [user, setUser] = useState<firebase.User | null>();
  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => setUser(user));
  }, []);
  const logout = useOnPromise(async () => {
    await firebase.auth().signOut();
  });
  return (
    <Page dotBackdrop width="800px" padding={0}>
      <Display
        mt="100px"
        title="Status Echo"
        caption={
          <>
            <p>
              Run{" "}
              <Text span b>
                Javascript
              </Text>{" "}
              online automatically with slack alerts.
            </p>
            <Link color={true} href="https://reily.app/echo">Documentations</Link>
          </>
        }
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Code size={60} />
          <Text h1 mb={0} mt={0} ml={1}>
            Echo
          </Text>
        </div>
      </Display>
      <Grid.Container justify="center" gap={3} mt="50px">
        <Grid
          xs={20}
          sm={8}
          justify="center"
          direction="column"
          alignItems="center"
        >
          <Button
            mt={1}
            shadow
            loading={user === undefined}
            icon={user == null ? <Github /> : <Avatar src={user.photoURL!} />}
            type={user == null ? "secondary-light" : "success"}
            width="100%"
            onClick={async () => {
              if (user != null) {
                history.push("/projects");
                return;
              }
              const provider = new firebase.auth.GithubAuthProvider();
              const result = await firebase.auth().signInWithPopup(provider);
              if (result.user != null) {
                history.push("/projects");
              }
            }}
          >
            {user == null ? "Sign in with GitHub" : "Continue to dashboard"}
          </Button>
          {user != null && (
            <Button
              mt={3}
              scale={0.7}
              type={"error"}
              ghost={true}
              icon={<UserX />}
              {...logout}
            >
              Logout
            </Button>
          )}
        </Grid>
      </Grid.Container>
    </Page>
  );
};

export default HomePage;
