import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from "react-router-dom";
import HomePage from "./flows/HomePage";
import ProjectsPage from "./flows/ProjectsPage";
import ProjectDetailPage from "./flows/ProjectDetailPage";
import useObservable from "./functions/useObservable";
import { currentUser$ } from "./functions/firebase";
import TaskDetailPage from "./flows/TaskDetailPage";

function ProtectedRoute(props: RouteProps) {
  const result = useObservable(currentUser$);
  if (result == null) {
    return null;
  }
  if (result.data == null) {
    return <Redirect to={"/"} />;
  }
  return <Route {...props} />;
}

function App() {
  return (
    <Router>
      <Switch>
        <ProtectedRoute
          path="/projects/:projectId/tasks/:taskId"
          component={TaskDetailPage}
        />
        <ProtectedRoute
          path="/projects/:projectId"
          component={ProjectDetailPage}
        />
        <ProtectedRoute path="/projects" component={ProjectsPage} />
        <Route path="/" component={HomePage} />
      </Switch>
    </Router>
  );
}

export default App;
