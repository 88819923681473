import {
  Breadcrumbs,
  Button,
  Card,
  Grid,
  Note,
  Page,
  Text,
  Avatar,
  Spacer,
  Dot,
} from "@geist-ui/react";
import React, { memo, useMemo } from "react";
import {
  collectionDataWithId,
  currentUser$,
  firestore,
  useDoc,
  WithID,
} from "../functions/firebase";
import { EMPTY, switchMap } from "rxjs";
import useObservable from "../functions/useObservable";
import { Link, useHistory } from "react-router-dom";
import { useOnPromise } from "../functions/useOnPromise";
import firebase from "firebase/app";
import { Code, PlusCircle } from "@geist-ui/react-icons";
import { Project } from "../functions/FirestoreTypes";

const projects$ = currentUser$.pipe(
  switchMap((user) =>
    user == null
      ? EMPTY
      : collectionDataWithId(
          firestore()
            .collection("projects")
            .where("enabled", "==", true)
            .where("userIds", "array-contains", user.uid)
        )
  )
);

const NewProjectButton = () => {
  const history = useHistory();
  const buttons = useOnPromise(async () => {
    const uid = firebase.auth().currentUser?.uid!;
    const result = await firestore()
      .collection("projects")
      .add({ name: "", userIds: [uid], ownerId: uid, enabled: false });
    history.push(`/projects/${result.id}`);
  });
  return (
    <Button width="100%" {...buttons} ghost={true} height="110px">
      <PlusCircle />
      <Spacer />
      Add new project
    </Button>
  );
};

const MemberAvatar = memo((props: { userId: string }) => {
  const profileDoc = useMemo(
    () => firestore().collection("profiles").doc(props.userId),
    [props.userId]
  );
  const profile = useDoc(profileDoc);
  return <Avatar src={profile.state.avatar} mr={1} />;
});

const ProjectCell = ({ project }: { project: WithID<Project> }) => {
  const tasks = useObservable(
    useMemo(
      () =>
        collectionDataWithId(
          firestore()
            .collection("projects")
            .doc(project.id)
            .collection("tasks")
            .where("enabled", "==", true)
        ),
      [project.id]
    )
  );
  const hasFailedTasks =
    tasks?.data != null &&
    tasks.data.some((t) => t.lastSavedExecution?.type === "failure");
  return (
    <Grid xs={24} md={8}>
      <Link to={`/projects/${project.id}`} style={{ width: "100%" }}>
        <Card shadow width="100%" height="100%">
          <Text h4>{project.name}</Text>
          <Dot type={hasFailedTasks ? "error" : "success"}>
            <span style={{ textTransform: "none" }}>
              {hasFailedTasks
                ? (tasks?.data ?? [])
                    .filter((t) => t.lastSavedExecution.type === "failure")
                    .map((t) => t.name)
                    .join(", ") + " is failing"
                : `${tasks?.data?.length ?? 0} tasks`}
            </span>
          </Dot>
          <p>
            {project.userIds.map((uid) => (
              <MemberAvatar key={uid} userId={uid} />
            ))}
          </p>
        </Card>
      </Link>
    </Grid>
  );
};

export function HomeBreadcrumb() {
  return (
    <Link to="/">
      <Breadcrumbs.Item>
        <Code size={25} />
        Echo
      </Breadcrumbs.Item>
    </Link>
  );
}

const ProjectsPage = () => {
  const projects = useObservable(projects$);
  return (
    <Page>
      <Breadcrumbs mb={2}>
        <HomeBreadcrumb />
        <Breadcrumbs.Item>Projects</Breadcrumbs.Item>
      </Breadcrumbs>
      {projects?.error && (
        <Note label="Error" type="error" mb={2}>
          {projects.error.message}
        </Note>
      )}
      <Text h3>My Projects</Text>
      {projects?.error && <Note type="error">{projects.error.message}</Note>}
      <Grid.Container gap={2}>
        {projects?.data?.map((project) => (
          <ProjectCell key={project.id} project={project} />
        ))}
        <Grid xs={24} md={8}>
          <Card shadow width="100%">
            <NewProjectButton />
          </Card>
        </Grid>
      </Grid.Container>
    </Page>
  );
};

export default ProjectsPage;
