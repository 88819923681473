import { useCallback, useState } from "react";

export function useOnPromise<I extends any[], O>(
  action: (...input: I) => Promise<O>
) {
  const [loading, setLoading] = useState(false);
  const onClick = useCallback(
    async (...i: I) => {
      try {
        setLoading(true);
        return await action(...i);
      } catch (e) {
        alert((e as Error).message);
      } finally {
        setLoading(false);
      }
    },
    [action]
  );
  return { loading, onClick };
}
