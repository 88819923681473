import { Observable } from "rxjs";
import { useEffect, useState } from "react";

export default function useObservable<T>(observable$: Observable<T>) {
  const [result, setResult] = useState<{ data?: T; error?: Error }>();
  useEffect(() => {
    const sub = observable$.subscribe({
      next: (value) => setResult({ data: value, error: undefined }),
      error: (err) => setResult({ data: undefined, error: err }),
    });
    return () => sub.unsubscribe();
  }, [observable$]);
  return result;
}
