import firebase from "firebase";
import { firebaseConfig, REGION } from "./config";

export const app = firebase.initializeApp(firebaseConfig);
export const functionsInRegion = () => app.functions(REGION);

export const setupFirestore = () => {
  firebase.firestore().settings({ ignoreUndefinedProperties: true });

  if (window.location.hostname === "localhost") {
    firebase.auth().useEmulator("http://localhost:9099");
    firebase.firestore().useEmulator("localhost", 8080);
    firebase.functions().useEmulator("localhost", 5001);
    firebase.database().useEmulator("localhost", 9000);
  }

  firebase.firestore().enablePersistence().catch();
};
