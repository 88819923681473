import firebase from "firebase/app";
import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Dot, Spacer, Table } from "@geist-ui/react";
import { WithID } from "../functions/firebase";
import { Execution } from "../functions/FirestoreTypes";

const ExecutionHistoryTable = (props: {
  projectId: string;
  taskId: string;
}) => {
  const [executions, setExecutions] = useState<WithID<Execution>[]>([]);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState<"all" | "errorOnly">("all");
  useEffect(() => {
    const query = firebase
      .database()
      .ref(
        `projects/${props.projectId}/${
          filter === "all" ? "taskExecutions" : "taskFailedExecutions"
        }/${props.taskId}`
      )
      .orderByChild("date")
      .limitToLast(limit);
    const callback = (a: firebase.database.DataSnapshot) => {
      const value = a.val();
      const result: WithID<Execution>[] = [];
      for (const key of Object.keys(value ?? {}).reverse()) {
        result.push({ id: key, ...value[key] });
      }
      setExecutions(result);
    };
    query.on("value", callback);
    return () => query.off("value");
  }, [props.projectId, props.taskId, limit, filter]);
  return (
    <>
      <ButtonGroup scale={1 / 3} margin={0}>
        <Button scale={1 / 3} onClick={() => setFilter("all")}>
          All history
        </Button>
        <Button scale={1 / 3} onClick={() => setFilter("errorOnly")}>
          Error only
        </Button>
      </ButtonGroup>
      <Spacer />
      <Table<WithID<Execution>> data={executions}>
        <Table.Column width={200} prop="id" label="ID" />
        <Table.Column
          prop="type"
          width={100}
          label="Status"
          render={(type) => (
            <>
              {type === "success" ? (
                <Dot type="success" />
              ) : (
                <Dot type="error" />
              )}
              {type}
            </>
          )}
        />
        <Table.Column
          width={200}
          prop="date"
          label="Date"
          render={(value) => <span>{new Date(value).toLocaleString()}</span>}
        />
        <Table.Column
          width={100}
          prop="durationInMs"
          label="Duration"
          render={(value) => (
            <span>{value != null ? `${value / 1000}s` : "--"}</span>
          )}
        />
        <Table.Column prop="log" label="Log" />
        <Table.Column prop="error" label="Error" />
      </Table>
      <Button
        disabled={limit > executions.length}
        mt={1}
        onClick={() => setLimit((p) => p + 10)}
      >
        Load More
      </Button>
    </>
  );
};

export default ExecutionHistoryTable;
