import React, { memo, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Avatar,
  Breadcrumbs,
  Button,
  Dot,
  Input,
  Modal,
  Note,
  Page,
  Table,
  Text,
  useToasts,
} from "@geist-ui/react";
import {
  collectionDataWithId,
  docDataWithId,
  firestore,
  useDoc,
  WithID,
} from "../functions/firebase";
import useObservable from "../functions/useObservable";
import { useOnPromise } from "../functions/useOnPromise";
import { combineLatest, map, Observable, of, startWith } from "rxjs";
import { Profile, Project, Task } from "../functions/FirestoreTypes";
import { HomeBreadcrumb } from "./ProjectsPage";

const AddTask = (props: { projectId: string }) => {
  const history = useHistory();
  const button = useOnPromise(async () => {
    const result = await firestore()
      .collection("projects")
      .doc(props.projectId)
      .collection("tasks")
      .add({
        name: "",
        input: "",
        command: { type: "custom", code: "" },
        frequency: "every 10 minutes",
        slackWebhook: "",
        enabled: false,
        notifyOn: "state-changed",
        // default new task to success
        lastSavedExecution: {
          type: "success",
          date: new Date().getTime(),
        },
      });
    history.push(`/projects/${props.projectId}/tasks/${result.id}`);
  });
  return (
    <Button type="secondary-light" mt="20px" {...button}>
      Add
    </Button>
  );
};

function InviteUser(props: { projectId: string }) {
  const [email, setEmail] = useState("");
  const toast = useToasts()[1];
  const submit = useOnPromise(async () => {
    const doc = firestore().collection("projects").doc(props.projectId);
    const project = (await doc.get()).data()!;
    const existingUser = (
      await firestore().collection("profiles").where("email", "==", email).get()
    ).docs[0];
    if (existingUser != null) {
      await doc.update("userIds", project.userIds.concat(existingUser.id));
      toast({ text: `Successfully added ${email} to this project` });
    } else {
      await doc.update(
        "invitations",
        (project.invitations ?? []).concat(email)
      );
      toast({ text: `Invited ${email} to this project` });
    }
    setEmail("");
  });
  return (
    <div>
      <Input
        label="Email"
        placeholder="john@apple.com"
        mr={1}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button type="secondary" ghost scale={0.8} {...submit}>
        Invite
      </Button>
    </div>
  );
}

export function combineLatestArray<T>(input: Observable<T>[]) {
  return input.length === 0 ? of([]) : combineLatest(input);
}

const UserTable = memo((props: { invites?: string[]; userIds: string[] }) => {
  const users$ = useMemo(() => {
    return combineLatestArray(
      props.userIds.map((uid) =>
        docDataWithId(firestore().collection("profiles").doc(uid))
      )
    ).pipe(
      startWith([] as WithID<Profile>[]),
      map((us) =>
        us.concat((props.invites ?? []).map((e) => ({ email: e } as any)))
      )
    );
  }, [props.invites, props.userIds]);
  const users = useObservable(users$);
  return (
    <Table<WithID<Profile>>
      data={users?.data ?? []}
      style={{ marginTop: "2em" }}
    >
      <Table.Column prop="email" label="Email" />
      <Table.Column prop="name" label="Name" />
      <Table.Column
        prop="avatar"
        label="Avatar"
        render={(value) => <Avatar src={value} />}
      />
    </Table>
  );
});

const DeleteProjectButton = ({ projectId }: { projectId: string }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const history = useHistory();
  const onDelete = useOnPromise(async () => {
    const ref = firestore().collection("projects").doc(projectId);
    const taskSnap = await ref
      .collection("tasks")
      .where("enabled", "==", true)
      .get();
    for (const doc of taskSnap.docs) {
      await doc.ref.set({ enabled: false }, { merge: true });
    }
    await ref.set({ enabled: false }, { merge: true });
    setShowDeleteModal(false);
    history.replace("/projects");
  });
  return (
    <>
      <Button type={"error"} ghost onClick={() => setShowDeleteModal(true)}>
        Delete
      </Button>
      <Modal
        visible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      >
        <Modal.Title>Danger</Modal.Title>
        <Modal.Subtitle>This can not be undone</Modal.Subtitle>
        <Modal.Content>
          <p>Are you sure you want to delete this project?</p>
        </Modal.Content>
        <Modal.Action passive onClick={() => setShowDeleteModal(false)}>
          Cancel
        </Modal.Action>
        <Modal.Action type={"error"} {...onDelete}>
          Delete
        </Modal.Action>
      </Modal>
    </>
  );
};

const ProjectDetailPage = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const projectDoc = useMemo(
    () => firestore().collection("projects").doc(projectId),
    [projectId]
  );
  const tasks = useObservable(
    useMemo(
      () =>
        collectionDataWithId(
          projectDoc
            .collection("tasks")
            .where("enabled", "==", true)
            .orderBy("lastSavedExecution.date", "desc")
        ),
      [projectDoc]
    )
  );
  const projectDetail = useDoc<Project>(projectDoc);
  return (
    <Page>
      <Breadcrumbs mb={2}>
        <HomeBreadcrumb />
        <Link to="/projects">
          <Breadcrumbs.Item>Projects</Breadcrumbs.Item>
        </Link>
        <Breadcrumbs.Item>
          {projectDetail.state.name || "Project Detail"}
        </Breadcrumbs.Item>
      </Breadcrumbs>
      {tasks?.error && (
        <Note label="Error" type="error" mb={2}>
          {tasks.error.message}
        </Note>
      )}
      <Input
        label="Project Name"
        mb="20px"
        value={projectDetail.state.name ?? ""}
        onChange={(e) =>
          projectDetail.setState((p) => ({ ...p, name: e.target.value }))
        }
        onBlur={() =>
          projectDetail.save({
            ...projectDetail.state,
            enabled: true,
          })
        }
      />
      <Text h3>Tasks</Text>
      <Table<WithID<Task>> data={tasks?.data ?? []}>
        <Table.Column
          width={50}
          prop="lastSavedExecution"
          label="Status"
          render={(value) => (
            <Dot type={value?.type === "success" ? "success" : "error"} />
          )}
        />
        <Table.Column<WithID<Task>>
          prop="name"
          label="Name"
          render={(name, { id }) => (
            <Link to={`/projects/${projectId}/tasks/${id}`}>{name}</Link>
          )}
        />
        <Table.Column
          prop="input"
          label="Input"
          render={(input) => (
            <div
              style={{
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical'
              }}
            >
              {input}
            </div>
          )}
        />
        <Table.Column prop="frequency" label="Frequency" />
        <Table.Column prop="notifyOn" label="Notify On" />
        <Table.Column
          prop="id"
          label="Operations"
          render={(value) => (
            <Link to={`/projects/${projectId}/tasks/${value}`}>
              <Button type="secondary" auto scale={1 / 3} ghost>
                Edit
              </Button>
            </Link>
          )}
        />
      </Table>
      <AddTask projectId={projectId} />
      <Text h3 mt={3}>
        Users
      </Text>
      <InviteUser projectId={projectId} />
      <UserTable
        userIds={projectDetail.state.userIds ?? []}
        invites={projectDetail.state.invitations}
      />
      <Text h3 mt={3}>
        Danger Zone
      </Text>
      <DeleteProjectButton projectId={projectId} />
    </Page>
  );
};

export default ProjectDetailPage;
