export const firebaseConfig = {
    apiKey: "AIzaSyCunk19UtwfWz9iwOqJXfMk-xBtfvmxnfU",
    authDomain: "echo-hk.firebaseapp.com",
    projectId: "echo-hk",
    storageBucket: "echo-hk.appspot.com",
    messagingSenderId: "1097942393226",
    appId: "1:1097942393226:web:838deae249a0591473a873",
    measurementId: "G-JM81RPV3G1"
};

export const REGION = "asia-east2";
